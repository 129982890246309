export default defineAppConfig({
  /* @ts-ignore: keep to fix build error */
  nuxtIcon: {},
  ui: {
    safelistColors: ['dark', 'secondary'],
    strategy: 'override',
    primary: 'dark',
    gray: 'charcoal',
    card: {
      ring: 'ring-1 ring-gray-500 dark:ring-gray-800',
      divide: 'divide-y divide-gray-100 dark:divide-gray-800',
      tbody: 'divide-y divide-gray-100 dark:divide-gray-800',
      shadow: '',
      rounded: 'rounded-lg',
      header: {
        padding: 'px-4 py-3 sm:px-4'
      },
      footer: {
        padding: 'px-4 py-3 sm:px-4'
      }
    },
    table: {
      divide: 'divide-y divide-gray-100 dark:divide-gray-700',
      tbody: 'divide-y divide-gray-100 dark:divide-gray-800',
      th: {
        font: 'font-bold',
        size: 'text-xs',
        padding: 'px-3 py-2'
      },
      td: {
        padding: 'px-3 py-3',
        color: 'text-body dark:text-gray-400'
      },
      default: {
        sortAscIcon: 'i-heroicons-bars-arrow-up',
        sortDescIcon: 'i-heroicons-bars-arrow-down',
        sortButton: {
          icon: 'i-heroicons-arrows-up-down',
          size: 'xs'
        },
        loadingState: {
          icon: 'i-tabler-loader-2'
        }
      }
    },
    notifications: {
      wrapper: 'fixed flex flex-col justify-end z-notification',
      position: 'bottom-0 end-0 start-0 mx-auto'
    },
    notification: {
      // background: 'bg-gray-700',
      background: 'bg-{color}-500',
      title: 'text-sm font-medium text-white',
      description: 'mt-1 text-sm leading-4 text-gray-400',
      container: 'relative overflow-hidden',
      gap: 'gap-3 z-10 relative',
      icon: {
        base: 'flex-shrink-0 w-5 h-5',
        color: 'text-white dark:text-white'
      },
      progress: {
        base: 'absolute bottom-0 end-0 start-0 z-0 h-1',
        background: 'bg-black/20 dark:bg-{color}-400'
      },
      transition: {
        enterActiveClass: 'transform ease-out duration-300 transition',
        enterFromClass: 'translate-y-8 opacity-0 2sm:translate-y-0 2sm:translate-x-8',
        enterToClass: 'translate-y-0 opacity-100 sm:translate-x-0',
        leaveActiveClass: 'transition ease-in duration-500',
        leaveFromClass: 'opacity-100',
        leaveToClass: 'opacity-0'
      },
      default: {
        color: 'alternate',
        closeButton: {
          color: 'white',
          variant: 'ghost'
        }
      }
    },
    button: {
      base: 'justify-center focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0',
      font: 'font-normal',
      rounded: 'rounded-full',
      size: {
        '2xs': 'text-xs',
        xs: 'text-xs',
        sm: 'text-sm',
        md: 'text-sm',
        lg: 'text-base',
        xl: 'text-lg'
      },
      padding: {
        '2xs': 'px-2 py-1',
        xs: 'px-3 py-1.5',
        sm: 'px-4 py-1.5',
        md: 'px-5 py-2.5',
        lg: 'px-6 py-2.5',
        xl: 'px-7 py-2.5'
      },
      default: {
        color: 'primary',
        loadingIcon: 'i-tabler-loader-2'
      },
      color: {
        white: {
          ghost:
            'text-white hover:bg-white/10 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400'
        },
        gray: {
          soft: 'text-gray-700 dark:text-white bg-gray-300/10 hover:bg-gray-300/20 disabled:bg-gray-50 dark:bg-white/10 dark:hover:bg-white/20 dark:disabled:bg-white/5 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-gray-500 dark:focus-visible:ring-white'
        }
      },
      variant: {
        solid:
          'shadow-none text-white dark:text-gray-900 bg-{color}-500 hover:bg-{color}-600 disabled:bg-{color}-500 dark:bg-{color}-400 dark:hover:bg-{color}-500 dark:disabled:bg-{color}-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-{color}-500 dark:focus-visible:outline-{color}-400'
      }
    },
    verticalNavigation: {
      rounded: 'rounded-lg before:rounded-lg',
      active: 'text-primary-600 dark:text-white before:bg-gray-100 dark:before:bg-gray-800',
      inactive:
        'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:before:bg-gray-50 dark:hover:before:bg-gray-800/50',
      badge: {
        active: 'bg-white dark:bg-gray-900',
        inactive:
          'bg-primary-500 dark:bg-gray-800 text-white dark:text-white group-hover:bg-primary-500 dark:group-hover:bg-gray-900'
      }
    },
    formGroup: {
      help: 'mt-1 text-xs text-gray-500 dark:text-gray-400',
      error: 'mt-1 text-xs font-medium text-red-500 dark:text-red-400'
    },
    input: {
      rounded: 'rounded-lg',
      color: {
        white: {
          outline:
            'shadow-none bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-600 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400'
        }
      },
      variant: {
        outline:
          'shadow-none bg-transparent text-gray-900 dark:text-white ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400 focus:ring-2 focus:ring-{color}-500 dark:focus:ring-{color}-400'
      }
    },
    dropdown: {
      ring: 'ring-1 ring-gray-100 dark:ring-gray-800',
      divide: 'divide-y divide-gray-100 dark:divide-gray-700',
      item: {
        active: 'bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white',
        inactive: 'text-gray-800 dark:text-gray-200'
      }
    },
    badge: {
      base: 'inline-flex items-center justify-center',
      font: 'font-bold',
      variant: {
        soft: 'bg-{color}-500/10 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400'
      }
    },
    modal: {
      wrapper: 'relative z-modal',
      overlay: {
        background: 'bg-gray-200/75 dark:bg-gray-800/75 backdrop-blur-sm\t'
      }
    },
    slideover: {
      wrapper: 'fixed inset-0 flex z-modal'
    }
  }
})
