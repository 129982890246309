import { initializeFaro } from '@grafana/faro-web-sdk'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const grafanaUrl = config.public.grafana_faro_url
  const grafanaName = config.public.grafana_faro_name
  if (!grafanaUrl || grafanaUrl === '' || !grafanaName || grafanaName === '') {
    return
  }

  initializeFaro({
    url: grafanaUrl,
    app: {
      name: grafanaName,
      version: '1.0.0'
    }
  })
})
