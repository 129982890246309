import { default as addresses4VpSQFI3HSMeta } from "/app/apps/customer-portal/pages/addresses.vue?macro=true";
import { default as assessmentlsrZunWbRGMeta } from "/app/apps/customer-portal/pages/assessment.vue?macro=true";
import { default as forgot_45password5ik6dbN5kAMeta } from "/app/apps/customer-portal/pages/auth/forgot-password.vue?macro=true";
import { default as login_45with_45passwordlessVhnNFlLqWHMeta } from "/app/apps/customer-portal/pages/auth/login-with-passwordless.vue?macro=true";
import { default as logineunpHX8WLcMeta } from "/app/apps/customer-portal/pages/auth/login.vue?macro=true";
import { default as finished19xmZSJVyAMeta } from "/app/apps/customer-portal/pages/auth/oauth2/finished.vue?macro=true";
import { default as redirectglt8Om3KQfMeta } from "/app/apps/customer-portal/pages/auth/oauth2/redirect.vue?macro=true";
import { default as registerniQJivTjrvMeta } from "/app/apps/customer-portal/pages/auth/register.vue?macro=true";
import { default as reset_45passwordnpP0zoHyvlMeta } from "/app/apps/customer-portal/pages/auth/reset-password.vue?macro=true";
import { default as verifyb5NWVoZV9kMeta } from "/app/apps/customer-portal/pages/auth/verify.vue?macro=true";
import { default as indexe2colRyiVxMeta } from "/app/apps/customer-portal/pages/index.vue?macro=true";
import { default as managedafvVsZ8oIMeta } from "/app/apps/customer-portal/pages/manage.vue?macro=true";
import { default as _91id_93EV3UrUEfBFMeta } from "/app/apps/customer-portal/pages/orders/[id].vue?macro=true";
import { default as indexlZrmadsi4pMeta } from "/app/apps/customer-portal/pages/orders/index.vue?macro=true";
import { default as referralsvBYvyPRxYkMeta } from "/app/apps/customer-portal/pages/referrals.vue?macro=true";
import { default as _91id_93aAsrmh6ZXxMeta } from "/app/apps/customer-portal/pages/subscriptions/[id].vue?macro=true";
import { default as indexPuILP2zvv7Meta } from "/app/apps/customer-portal/pages/subscriptions/index.vue?macro=true";
import { default as payment_45methodspYpcrlX8plMeta } from "/app/apps/customer-portal/pages/subscriptions/payment-methods.vue?macro=true";
import { default as upcoming_45orderszHPYATzh3QMeta } from "/app/apps/customer-portal/pages/subscriptions/upcoming-orders.vue?macro=true";
import { default as test_45sentryvhRnZLpu5iMeta } from "/app/apps/customer-portal/pages/test-sentry.vue?macro=true";
export default [
  {
    name: addresses4VpSQFI3HSMeta?.name ?? "addresses",
    path: addresses4VpSQFI3HSMeta?.path ?? "/addresses",
    meta: addresses4VpSQFI3HSMeta || {},
    alias: addresses4VpSQFI3HSMeta?.alias || [],
    redirect: addresses4VpSQFI3HSMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/addresses.vue").then(m => m.default || m)
  },
  {
    name: assessmentlsrZunWbRGMeta?.name ?? "assessment",
    path: assessmentlsrZunWbRGMeta?.path ?? "/assessment",
    meta: assessmentlsrZunWbRGMeta || {},
    alias: assessmentlsrZunWbRGMeta?.alias || [],
    redirect: assessmentlsrZunWbRGMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/assessment.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password5ik6dbN5kAMeta?.name ?? "auth-forgot-password",
    path: forgot_45password5ik6dbN5kAMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45password5ik6dbN5kAMeta || {},
    alias: forgot_45password5ik6dbN5kAMeta?.alias || [],
    redirect: forgot_45password5ik6dbN5kAMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: login_45with_45passwordlessVhnNFlLqWHMeta?.name ?? "auth-login-with-passwordless",
    path: login_45with_45passwordlessVhnNFlLqWHMeta?.path ?? "/auth/login-with-passwordless",
    meta: login_45with_45passwordlessVhnNFlLqWHMeta || {},
    alias: login_45with_45passwordlessVhnNFlLqWHMeta?.alias || [],
    redirect: login_45with_45passwordlessVhnNFlLqWHMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/login-with-passwordless.vue").then(m => m.default || m)
  },
  {
    name: logineunpHX8WLcMeta?.name ?? "auth-login",
    path: logineunpHX8WLcMeta?.path ?? "/auth/login",
    meta: logineunpHX8WLcMeta || {},
    alias: logineunpHX8WLcMeta?.alias || [],
    redirect: logineunpHX8WLcMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: finished19xmZSJVyAMeta?.name ?? "auth-oauth2-finished",
    path: finished19xmZSJVyAMeta?.path ?? "/auth/oauth2/finished",
    meta: finished19xmZSJVyAMeta || {},
    alias: finished19xmZSJVyAMeta?.alias || [],
    redirect: finished19xmZSJVyAMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/oauth2/finished.vue").then(m => m.default || m)
  },
  {
    name: redirectglt8Om3KQfMeta?.name ?? "auth-oauth2-redirect",
    path: redirectglt8Om3KQfMeta?.path ?? "/auth/oauth2/redirect",
    meta: redirectglt8Om3KQfMeta || {},
    alias: redirectglt8Om3KQfMeta?.alias || [],
    redirect: redirectglt8Om3KQfMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/oauth2/redirect.vue").then(m => m.default || m)
  },
  {
    name: registerniQJivTjrvMeta?.name ?? "auth-register",
    path: registerniQJivTjrvMeta?.path ?? "/auth/register",
    meta: registerniQJivTjrvMeta || {},
    alias: registerniQJivTjrvMeta?.alias || [],
    redirect: registerniQJivTjrvMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordnpP0zoHyvlMeta?.name ?? "auth-reset-password",
    path: reset_45passwordnpP0zoHyvlMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordnpP0zoHyvlMeta || {},
    alias: reset_45passwordnpP0zoHyvlMeta?.alias || [],
    redirect: reset_45passwordnpP0zoHyvlMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: verifyb5NWVoZV9kMeta?.name ?? "auth-verify",
    path: verifyb5NWVoZV9kMeta?.path ?? "/auth/verify",
    meta: verifyb5NWVoZV9kMeta || {},
    alias: verifyb5NWVoZV9kMeta?.alias || [],
    redirect: verifyb5NWVoZV9kMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: indexe2colRyiVxMeta?.name ?? "index",
    path: indexe2colRyiVxMeta?.path ?? "/",
    meta: indexe2colRyiVxMeta || {},
    alias: indexe2colRyiVxMeta?.alias || [],
    redirect: indexe2colRyiVxMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: managedafvVsZ8oIMeta?.name ?? "manage",
    path: managedafvVsZ8oIMeta?.path ?? "/manage",
    meta: managedafvVsZ8oIMeta || {},
    alias: managedafvVsZ8oIMeta?.alias || [],
    redirect: managedafvVsZ8oIMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/manage.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EV3UrUEfBFMeta?.name ?? "orders-id",
    path: _91id_93EV3UrUEfBFMeta?.path ?? "/orders/:id()",
    meta: _91id_93EV3UrUEfBFMeta || {},
    alias: _91id_93EV3UrUEfBFMeta?.alias || [],
    redirect: _91id_93EV3UrUEfBFMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlZrmadsi4pMeta?.name ?? "orders",
    path: indexlZrmadsi4pMeta?.path ?? "/orders",
    meta: indexlZrmadsi4pMeta || {},
    alias: indexlZrmadsi4pMeta?.alias || [],
    redirect: indexlZrmadsi4pMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: referralsvBYvyPRxYkMeta?.name ?? "referrals",
    path: referralsvBYvyPRxYkMeta?.path ?? "/referrals",
    meta: referralsvBYvyPRxYkMeta || {},
    alias: referralsvBYvyPRxYkMeta?.alias || [],
    redirect: referralsvBYvyPRxYkMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aAsrmh6ZXxMeta?.name ?? "subscriptions-id",
    path: _91id_93aAsrmh6ZXxMeta?.path ?? "/subscriptions/:id()",
    meta: _91id_93aAsrmh6ZXxMeta || {},
    alias: _91id_93aAsrmh6ZXxMeta?.alias || [],
    redirect: _91id_93aAsrmh6ZXxMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/subscriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPuILP2zvv7Meta?.name ?? "subscriptions",
    path: indexPuILP2zvv7Meta?.path ?? "/subscriptions",
    meta: indexPuILP2zvv7Meta || {},
    alias: indexPuILP2zvv7Meta?.alias || [],
    redirect: indexPuILP2zvv7Meta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodspYpcrlX8plMeta?.name ?? "subscriptions-payment-methods",
    path: payment_45methodspYpcrlX8plMeta?.path ?? "/subscriptions/payment-methods",
    meta: payment_45methodspYpcrlX8plMeta || {},
    alias: payment_45methodspYpcrlX8plMeta?.alias || [],
    redirect: payment_45methodspYpcrlX8plMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/subscriptions/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: upcoming_45orderszHPYATzh3QMeta?.name ?? "subscriptions-upcoming-orders",
    path: upcoming_45orderszHPYATzh3QMeta?.path ?? "/subscriptions/upcoming-orders",
    meta: upcoming_45orderszHPYATzh3QMeta || {},
    alias: upcoming_45orderszHPYATzh3QMeta?.alias || [],
    redirect: upcoming_45orderszHPYATzh3QMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/subscriptions/upcoming-orders.vue").then(m => m.default || m)
  },
  {
    name: test_45sentryvhRnZLpu5iMeta?.name ?? "test-sentry",
    path: test_45sentryvhRnZLpu5iMeta?.path ?? "/test-sentry",
    meta: test_45sentryvhRnZLpu5iMeta || {},
    alias: test_45sentryvhRnZLpu5iMeta?.alias || [],
    redirect: test_45sentryvhRnZLpu5iMeta?.redirect,
    component: () => import("/app/apps/customer-portal/pages/test-sentry.vue").then(m => m.default || m)
  }
]