import type { UserAuthMode, UserInterface } from 'har-shared/types/User.interface'
import { UserLoginMethodEnum, UserProviderEnum } from 'har-shared/types/User.interface'

const getSession = async () => {
  try {
    return await $fetch('/api/auth/session', {
      credentials: 'include'
    })
  } catch (e) {
    //
  }
  return null
}

const checkEmail = async (
  email: string
): Promise<
  | {
      details?: {
        provider?: UserProviderEnum
        login_method?: UserLoginMethodEnum
        url?: string
      }
    }
  | string
> => {
  return await $fetch('/api/public/auth/login-details', {
    method: 'GET',
    query: {
      email
    }
  })
}

const signIn = async (
  mode: UserAuthMode = 'credentials',
  credentials: any,
  opts?: {
    callbackUrl?: string
    locale?: string
    external?: boolean
    redirect?: boolean
  }
) => {
  let endpoint = '/api/auth/login'
  // if (credentials.oauth_token) {
  if (mode === 'oauth') {
    endpoint = '/api/auth/login-oauth'
  }
  const result = await $fetch(endpoint, {
    method: 'POST',
    headers: opts?.locale
      ? {
          'Accept-Language': opts.locale
        }
      : {},
    body: {
      mode,
      ...credentials
    },
    query: {
      ...opts
    }
  })

  if (opts?.redirect === false) {
    return result
  }
  return navigateTo(opts?.callbackUrl ?? '/', { external: !!opts?.external })
}

const signOut = async (opts?: { callbackUrl?: string; external?: boolean }) => {
  await $fetch('/api/auth/logout', {
    method: 'POST',
    query: {
      ...opts
    }
  })

  return navigateTo('/auth/login', { external: true })
}

export function useAuth() {
  const user = useState<UserInterface | null>('auth-data', () => null)
  return {
    user,
    data: { user },
    checkEmail,
    signIn,
    signOut,
    getSession
  }
}
