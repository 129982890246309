import type { AnalyticsInstance, PageData } from 'analytics'

export function AnalyticDebuggerPlugin(userConfig = {}) {
  return {
    name: 'debugger-plugin',
    config: userConfig,
    initialize: ({ config }: any) => {
      console.info('AnalyticDebugger-init', config)
      const w = window as any
      if (w) w.AnalyticDebuggerPluginLoaded = true
    },
    page: ({ payload }: any) => {
      console.info('AnalyticDebugger-page', payload)
    },
    track: ({ payload }: any) => {
      console.info('AnalyticDebugger-track', payload)
    },
    identify: ({ payload }: any) => {
      console.info('AnalyticDebugger-identify', payload)
    },
    loaded: () => {
      return !!(window as any)?.AnalyticDebuggerPluginLoaded
    }
  }
}

export class Tracker {
  instance
  constructor(instance: AnalyticsInstance) {
    this.instance = instance
  }

  pageView(data?: PageData, opts?: any, callback?: () => void) {
    this.instance.page(data, opts, callback)
  }

  identify(userId: string, traits?: any, opts?: any) {
    this.instance.identify(userId, traits, opts)
  }

  track(eventName: string, data?: any) {
    this.instance.track(eventName, data)
  }

  loggedIn(data?: any) {
    this.instance.track('logged_in', data)
  }

  signUpStarted(data?: any) {
    this.instance.track('sign_up_started', data)
  }

  signedUp(data?: any) {
    this.instance.track('sign_up_completed', data)
  }

  personalDetailsUpdated(data?: any) {
    this.instance.track('personal_details_updated', data)
  }
}
