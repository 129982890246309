type MiddlewareMeta =
  | boolean
  | {
      /** Whether to only allow unauthenticated users to access this page.
       *
       * Authenticated users will be redirected to `/` or the route defined in `navigateAuthenticatedTo`
       *
       * @default undefined
       */
      unauthenticatedOnly?: boolean
      /** Where to redirect authenticated users if `unauthenticatedOnly` is set to true
       *
       * @default undefined
       */
      navigateAuthenticatedTo?: string
      /** Where to redirect unauthenticated users if this page is protected
       *
       * @default undefined
       */
      navigateUnauthenticatedTo?: string
    }

declare module '#app' {
  interface PageMeta {
    auth?: MiddlewareMeta
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    auth?: MiddlewareMeta
  }
}

export default defineNuxtRouteMiddleware((to) => {
  const metaAuth =
    typeof to.meta.auth === 'object'
      ? {
          unauthenticatedOnly: true,
          ...to.meta.auth
        }
      : to.meta.auth

  if (metaAuth === false) {
    return
  }
  const { user: session } = useAuth()

  const isGuestMode = typeof metaAuth === 'object' && metaAuth.unauthenticatedOnly
  // Guest mode happy path 1: Unauthenticated user is allowed to view page
  if (isGuestMode && !session.value) {
    return
  }

  // Guest mode edge-case: Developer used guest-mode config style but set `unauthenticatedOnly` to `false`
  if (typeof metaAuth === 'object' && !metaAuth.unauthenticatedOnly) {
    return
  }

  if (session.value) {
    // Guest mode happy path 2: Authenticated user should be directed to another page
    if (isGuestMode) {
      return navigateTo(metaAuth.navigateAuthenticatedTo ?? '/')
    }
    return
  }

  /**
   * We do not want to enforce protection on `404` pages (unless the user opts out of it by setting `allow404WithoutAuth: false`).
   */
  const matchedRoute = to.matched.length > 0
  if (!matchedRoute) {
    // Hands control back to `vue-router`, which will direct to the `404` page
    return
  }

  if (typeof metaAuth === 'object' && metaAuth.navigateUnauthenticatedTo) {
    return navigateTo(metaAuth.navigateUnauthenticatedTo)
  } else {
    return navigateTo(`/auth/login?callbackUrl=${encodeURIComponent(to.path)}`)
  }
})
