import { defineStore } from 'pinia'
import type { ShopifyCustomerInterface } from 'har-shared/types/shopify'
import type { LocalizationInterface } from 'har-shared/types/shopify/Localization.interface'
import type { ShopifyStoreItem } from 'har-shared/types/codes'

type State = {
  ready: boolean
  sidebarOpen: boolean
  activeStore: ShopifyStoreItem | null
  customerAccessToken: string | null
  customerAccessTokenId: string | null
  shopifyCustomer: ShopifyCustomerInterface | null
  localization: LocalizationInterface | null
}

export const useCommonStore = defineStore('common', {
  persist: {
    paths: ['activeStore', 'customerAccessToken', 'customerAccessTokenId'],
    afterRestore(context) {
      context.store.ready = true
    }
  },
  state: (): State => ({
    ready: false,
    sidebarOpen: false,
    activeStore: null,
    customerAccessToken: null,
    customerAccessTokenId: null,
    shopifyCustomer: null,
    localization: null
  }),
  getters: {
    locale: (state) => {
      const config = useRuntimeConfig()
      const shopifyStores = config.public.shopifyStores as Array<ShopifyStoreItem>
      return state.activeStore?.locale ?? shopifyStores[0].locale
    },
    country_code: (state) => {
      const config = useRuntimeConfig()
      const shopifyStores = config.public.shopifyStores as Array<ShopifyStoreItem>
      return state.activeStore?.code ?? shopifyStores[0].code
    }
  },
  actions: {
    toggleSidebar(open?: boolean) {
      this.sidebarOpen = open !== undefined ? open : !this.sidebarOpen
    },
    setActiveStore(store: ShopifyStoreItem) {
      this.activeStore = store
    },
    setActiveStoreByCode(code: string) {
      const config = useRuntimeConfig()
      const shopifyStores = config.public.shopifyStores as Array<ShopifyStoreItem>
      const match = shopifyStores.find((store) => store.code.toLowerCase() === code.toLowerCase())
      if (match) {
        this.activeStore = match
      }
    },
    setActiveStoreByOrigin(origin: string) {
      const config = useRuntimeConfig()
      const shopifyStores = config.public.shopifyStores as Array<ShopifyStoreItem>
      const match = shopifyStores.find((s) => {
        return origin.startsWith(s.portal_url)
      })
      console.log('match', origin, match)
      if (match) {
        this.activeStore = match
      }
    },
    setCustomerAccessToken(token: string | null, identifier: string | null) {
      this.customerAccessToken = token
      this.customerAccessTokenId = identifier
    },
    setShopifyCustomer(customer: ShopifyCustomerInterface) {
      this.shopifyCustomer = customer
    },
    setLocalization(data: LocalizationInterface | null) {
      this.localization = data
    }
  }
})
