import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import { AnalyticDebuggerPlugin, Tracker } from '~/lib/analytics'

export const useTracker = () => {
  return () => {
    const w = window as any
    if (w.AnalyticsTracker) {
      return w.AnalyticsTracker
    }
    const config = useRuntimeConfig()
    const AnalyticsPlugins = []
    if (config.public.debug === true) {
      AnalyticsPlugins.push(AnalyticDebuggerPlugin())
    }
    const gtmContainer = config.public.gtmContainer
    if (gtmContainer && gtmContainer !== '') {
      AnalyticsPlugins.push(
        googleTagManager({
          containerId: `${gtmContainer}`
        })
      )
    }
    const Util = Analytics({
      app: 'ha-account-analytics',
      debug: true,
      plugins: AnalyticsPlugins
    })

    w.AnalyticsTracker = new Tracker(Util)
    return w.AnalyticsTracker
  }
}
