export const AvailableLocales = {
  en: 'English',
  da: 'Danish',
  is: 'Icelandic',
  'en-us': 'English (US)',
  'en-ae': 'English (UAE)',
  'en-eu': 'English (Europe)'
}

export const DefaultLocale = 'en'
