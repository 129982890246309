import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/customer-portal/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2yIKADAg6h from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import script_UKdghfPT0q from "/app/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import slideovers_oeUu8xcI5w from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_dOTnCc6TUQ from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import sentry_client_H7JebPKzSG from "/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/apps/customer-portal/.nuxt/sentry-client-config.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_10gaYeCiJK from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import grafana_client_yt9vffW7y9 from "/app/apps/customer-portal/plugins/grafana.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_2yIKADAg6h,
  script_UKdghfPT0q,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  i18n_dOTnCc6TUQ,
  sentry_client_H7JebPKzSG,
  sentry_client_config_o34nk2sJbg,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_10gaYeCiJK,
  grafana_client_yt9vffW7y9
]